import React from 'react'
import './certificates.css'
import cert1 from '../../assets/certificate1.png'
import cert2 from '../../assets/certificate2.png'
import cert3 from '../../assets/certificate3.png'
import cert4 from '../../assets/certificate4.png'
import cert5 from '../../assets/certificate5.png'
import cert6 from '../../assets/certificate6.png'
import cert7 from '../../assets/certificate7.png'
import cert8 from '../../assets/certificate8.jpg'
import cert9 from '../../assets/certificate9.jpg'
import cert10 from '../../assets/certificate10.jpg'


import Cert1 from '../../assets/certificate1.pdf'
import Cert2 from '../../assets/certificate2.pdf'
import Cert3 from '../../assets/certificate3.pdf'
import Cert4 from '../../assets/certificate4.pdf'
import Cert5 from '../../assets/certificate5.pdf'
import Cert6 from '../../assets/certificate6.pdf'
import Cert7 from '../../assets/certificate7.pdf'
import Cert8 from '../../assets/certificate8.pdf'
import Cert9 from '../../assets/certificate9.jpg'
import Cert10 from '../../assets/certificate10.jpg'
// import Swiper core and required modules
import {Pagination} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const data = [
  {
    avatar: cert10,
    certificate: Cert10
  },
  {
    avatar: cert9,
    certificate: Cert9
  },
  {
    avatar: cert6,
    certificate: Cert6
  },
  {
    avatar: cert7,
    certificate: Cert7
  },
  {
    avatar: cert1,
    certificate: Cert1
  },
  {
    avatar: cert2,
    certificate: Cert2
  },
  {
    avatar: cert3,
    certificate: Cert3
  },
  {
    avatar: cert8,
    certificate: Cert8
  },
  {
    avatar: cert4,
    certificate: Cert4
  },
  {
    avatar: cert5,
    certificate: Cert5
  }
  
]

const Certificates = () => {
  return (
    <section id='certificate'>
      <h5>What Certificates I Have</h5>
      <h2>Certificates</h2>

      <Swiper className="container testimonials__container"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}>

        {
          data.map(({avatar, certificate}, index) => {
            return (
              <SwiperSlide key={index} className='testimonial__cert testimonial'>
                <div className="client__avatar">
                  <img src={avatar} />
                </div>

                <a href={certificate} download className='btn btn-primary'>Download</a>

                {/* <small className='client__review'>
                  {review}
                </small>   */}
              </SwiperSlide>
            )
          })
        }

      </Swiper>
    </section>
  )
}
export default Certificates