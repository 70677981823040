import React from 'react'
import './skills.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Skills = () => {
  return (
    <section id='experience'>
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>
      <div className="container experience__container">
        {/* my Skills Hereeeeeeeeeeeeee */}
        <div className="experience__frontend">   
           <h3>Embedded systems</h3>
           <div className="experience__content">
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Embedded C</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article> 

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Embedded system concepts</h4>
                <small className='text-light'>Basic</small>
              </div>
            </article> 

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Microcontroller interfacing AVR</h4>
                <small className='text-light'>Basic</small>
              </div>
            </article> 

           </div>
        </div>
        {/* Ende of Embedded */}


        <div className="experience__frontend">   
           <h3>Frontend Development</h3>
           <div className="experience__content">
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>HTML</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article> 

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>CSS</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article> 

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>React</h4>
                <small className='text-light'>Basic</small>
              </div>
            </article> 

           </div>
        </div>
        {/* Ende of Frontend */}

        <div className="experience__backend">
          <h3>Programming Languages</h3>
           <div className="experience__content">
           <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>C</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article> 
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Python</h4>
                <small className='text-light'>intermediate</small>
              </div>
            </article> 

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Matlab</h4>
                <small className='text-light'>Basic</small>
              </div>
            </article>

           </div>
        </div>

        
        <div className="experience__backend">
          <h3>Design's Programs</h3>
           <div className="experience__content">
           <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>SolidWorks</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article> 
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>AutoCad</h4>
                <small className='text-light'>intermediate</small>
              </div>
            </article> 

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Adobe photoshop</h4>
                <small className='text-light'>Basic</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Video Editing</h4>
                <small className='text-light'>Basic</small>
              </div>
            </article>

           </div>
        </div>


        <div className="experience__backend">
          <h3>Other Skills</h3>
           <div className="experience__content">
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>MicroSoft Office</h4>
                <small className='text-light'>intermediate</small>
              </div>
            </article> 

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>Arduino</h4>
                <small className='text-light'>intermediate</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>IOT(ESP8266)</h4>
                <small className='text-light'>Basic</small>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4>PLC</h4>
                <small className='text-light'>Basic</small>
              </div>
            </article>

           </div>
        </div>

      </div>

    </section>
  )
}

export default Skills