import React from 'react'
import './footer.css'
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {IoLogoTwitter} from 'react-icons/io'
import {GrLinkedinOption} from 'react-icons/gr'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>Hanna Dawoud</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#certificate">Certificates</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Books</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.facebook.com/profile.php?id=100028723907283" target='_blank'><FaFacebookF /></a>
        <a href="https://www.instagram.com/hanna_dawoud1/" target='_blank'><FiInstagram /></a>
        <a href="https://twitter.com/HannaSamsoum" target='_blank'><IoLogoTwitter /></a>
        <a href="https://www.linkedin.com/in/hanna-dawoud/" target='_blank'><GrLinkedinOption /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Hanna Dawoud. All rights reserved 2024.</small>
      </div>
    </footer>
  )
}

export default Footer