import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsYoutube} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/hanna-dawoud/" target='_blank'><BsLinkedin /></a>
        <a href="https://www.youtube.com/@HannaDawoud-tn4pn/featured" target='_blank'><BsYoutube/></a>
    </div>
  )
}

export default HeaderSocials