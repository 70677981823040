import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/project1.jpg'
import IMG2 from '../../assets/project2.jpg'
import IMG3 from '../../assets/book3.jpeg'
import IMG4 from '../../assets/book1.jpg'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Industrial Robotic arm',
    github: 'https://drive.google.com/drive/folders/14zOcpJp3o_qaKKy2Apr4WLye0hIFHzAm?usp=sharing',
    demo: 'https://youtu.be/rKRfQqpav_U'
  },
  {
    id: 2,
    image: IMG2,
    title: 'CNC Project',
    github: 'https://drive.google.com/drive/folders/11L3MhYHvTZnmr1-74pRuLq3o0Llv1v6S?usp=sharing',
    demo: 'https://youtu.be/S_OJrPDJp3Q'
  }

]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo}) => {
            return(
              <article key={id} className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={image} alt={title } />
                </div>
                <h3>{title}</h3>
                <div className='portfolio__item-cta'>
                      <a href={github} className='btn' target='_blank'>More about</a>
                      <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio