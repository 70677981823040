import React from 'react'
import './about.css'
import ME from '../../assets/me5.png'
import {PiCertificateBold} from "react-icons/pi";
import {ImBooks} from 'react-icons/im' 
import {AiOutlineFileDone} from 'react-icons/ai'
import {FaTasks} from "react-icons/fa"; 

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="me" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">

            <article className='about__card'>
              <PiCertificateBold  className='about__icon' />
              <h5>Certificates</h5>
              <small>10+ Completed</small>
            </article>

            <article className='about__card'>
              <ImBooks className='about__icon' />
              <h5>Books</h5>
              <small>5+ Readed</small>
            </article>
          
            <article className='about__card'>
              <FaTasks className='about__icon' />
              <h5>Projects</h5>
              <small>7+ Completed</small>
            </article>
          </div>
          <p>
          Hello, I'm Hanna Dawoud, I graduated from the Faculty of Engineering, major
          of Mechatronics and Robotics.<br/>
          <br/>
          I have a profound passion for web development.
          Driven by this enthusiasm, I created my personal website to showcase my courses, 
          projects, and books, reflecting my dedication to continuous learning and knowledge sharing.
          </p>
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div> 
      
    </section>
  )
}

export default About