import React from 'react'
import './testimonials.css'
import Avt1 from '../../assets/book1.jpg'
import Avt2 from '../../assets/book2.jpeg'
import Avt3 from '../../assets/book3.jpeg'
import Avt4 from '../../assets/book4.jpg'
import Avt5 from '../../assets/book5.jpg'

// import Swiper core and required modules
import {Pagination} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const data = [
  {
    avatar: Avt4, 
    name: '\"اغني رجل في بابل\"'
  },
  {
    avatar: Avt5, 
    name: '\"اسرار عقل المليونير\"'
  },
  {
    avatar: Avt3, 
    name: '\"فن اللامبالاة\"'
  },
  {
    avatar: Avt2,
    name: '\"من الذي حرك قطعة الجبن الخاصه بي؟\"'
  },
  {
    avatar: Avt1,
    name: '\"كيف تؤثر علي الاخرين وتكتسب الاصدقاء\"'
  }
  
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>What Books I Read</h5>
      <h2>Books</h2>

      <Swiper className="container testimonials__container"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}>

        {
          data.map(({avatar, name}, index) => {
            return (
              <SwiperSlide key={index} className='testimonial'>
                <div className="client__avatar">
                  <img src={avatar} alt={name} />
                </div>
                <h5 className='client__name'>{name}</h5>
                {/* <small className='client__review'>
                  {review}
                </small>   */}
              </SwiperSlide>
            )
          })
        }

      </Swiper>
    </section>
  )
}

export default Testimonials